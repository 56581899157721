import React, { useEffect } from "react";
import {
  Select,
  SelectValue,
  SelectContent,
  SelectTrigger,
} from "../ui/select";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { LoaderCircle } from "lucide-react";

const DeliveryAddressForm = ({
  shipCity,
  shipState,
  isLoading,
  bookOrders,
  shipPhoneNo,
  shipPinCode,
  setShipCity,
  addressLine1,
  addressLine2,
  addressLine3,
  setShipState,
  emailAddress,
  recipientName,
  setShipPhoneNo,
  setShipPinCode,
  selectedStatus,
  setAddressLine1,
  setAddressLine2,
  setAddressLine3,
  selectedAddress,
  setEmailAddress,
  setRecipientName,
  addUpdateAddress,
  setEditingAddress,
  setSelectedStatus,
  setSelectedAddress,
  handleSelectCountry,
  setAddingNewAddress,
  selectedShipConutry,
  showConformationMail,
  setSelectedShipConutry,
  setShowConformationMail,
}) => {
  useEffect(() => {
    if (selectedAddress) {
      setShipCity(selectedAddress?.ShipCityName || "");
      setShipState(selectedAddress?.ShipStateName || "");
      setShipPhoneNo(selectedAddress?.ShipPhoneNo || "");
      setShipPinCode(selectedAddress?.ShipPinCode || "");
      setEmailAddress(selectedAddress?.EmailAddress || "");
      setRecipientName(selectedAddress?.RecipientName || "");
      setAddressLine1(selectedAddress?.ShipAddressLine1 || "");
      setAddressLine2(selectedAddress?.ShipAddressLine2 || "");
      setAddressLine3(selectedAddress?.ShipAddressLine3 || "");

      const status = bookOrders?.deliveryAddressList?.StatusList?.find(
        (ele) => ele?.StatusId === selectedAddress?.StatusId,
      );
      setSelectedStatus(status || null);

      const country = bookOrders?.countryList?.find(
        (ele) => ele.CountryId === selectedAddress?.ShipCountryId,
      );
      setSelectedShipConutry(country || null);
    } else {
      setShipCity("");
      setShipState("");
      setShipPhoneNo("");
      setShipPinCode("");
      setEmailAddress("");
      setRecipientName("");
      setAddressLine1("");
      setAddressLine2("");
      setAddressLine3("");
      setSelectedStatus("");
      setSelectedShipConutry("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress, bookOrders]);

  const handleSelectStatus = (selectedValue) => {
    const selectedStatus = bookOrders?.deliveryAddressList?.StatusList?.find(
      (item) => item.StatusId.toString() === selectedValue,
    );
    setSelectedStatus(selectedStatus || null);
  };

  return (
    <div className="space-y-2">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="recipientName">Recipient Name</label>
          <Input
            id="recipientName"
            value={recipientName}
            placeholder="Enter recipient name"
            onChange={(e) => setRecipientName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="phoneNumner">Phone Numner</label>
          <Input
            id="phoneNumner"
            value={shipPhoneNo}
            placeholder="Enter phone numner"
            onChange={(e) => setShipPhoneNo(e.target.value)}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="emailAddress">Email Address</label>
          <Input
            id="emailAddress"
            value={emailAddress}
            placeholder="Enter email address"
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="status">Status</label>
          <Select
            required
            value={
              selectedStatus?.StatusId !== undefined
                ? selectedStatus.StatusId
                : ""
            }
            onValueChange={(value) => handleSelectStatus(value)}
          >
            <SelectTrigger id="status">
              <SelectValue placeholder="Select Status">
                {selectedStatus?.StatusText || "Select Status"}
              </SelectValue>
            </SelectTrigger>
            <SelectContent
              items={bookOrders?.deliveryAddressList?.StatusList?.map(
                (item) => ({
                  label: item.StatusText || "Unknown",
                  value: item.StatusId.toString(),
                }),
              )}
            />
          </Select>
        </div>
      </div>

      <div>
        <label htmlFor="addressLine1">Address Line 1</label>
        <Input
          id="addressLine1"
          value={addressLine1}
          placeholder="Enter your address line 1"
          onChange={(e) => setAddressLine1(e.target.value)}
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="addressLine2">Address Line 2</label>
          <Input
            id="addressLine2"
            value={addressLine2}
            placeholder="Enter your address line 2"
            onChange={(e) => setAddressLine2(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="addressLine3">Address Line 3</label>
          <Input
            id="addressLine3"
            value={addressLine3}
            placeholder="Enter your address line 3"
            onChange={(e) => setAddressLine3(e.target.value)}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="pinCode">Pin Code</label>
          <Input
            id="pinCode"
            value={shipPinCode}
            placeholder="Enter pin code"
            onChange={(e) => setShipPinCode(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="shipCountry">Country</label>
          <Select
            required
            value={selectedShipConutry?.CountryId}
            onValueChange={(value) =>
              handleSelectCountry(
                bookOrders?.countryList.find(
                  (item) => item.CountryId === value,
                ),
              )
            }
          >
            <SelectTrigger id="shipCountry">
              <SelectValue placeholder="Select Country">
                {selectedShipConutry?.CountryName || "Select Country"}
              </SelectValue>
            </SelectTrigger>
            <SelectContent
              items={bookOrders?.countryList?.map((item) => ({
                label: item.CountryName || "Unknown",
                value: item.CountryId || "unknown",
              }))}
            />
          </Select>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="shipCity">City</label>
          <Input
            id="shipCity"
            value={shipCity}
            placeholder="Enter city name"
            onChange={(e) => setShipCity(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="shipStateName">State</label>
          <Input
            id="shipStateName"
            value={shipState}
            placeholder="Enter state name"
            onChange={(e) => setShipState(e.target.value)}
          />
        </div>
      </div>

      <div className="flex gap-4">
        <Button
          disabled={isLoading}
          className="bg-purple-1 text-white hover:bg-gray-200 hover:font-medium hover:text-purple-1"
          onClick={() => {
            addUpdateAddress(selectedAddress);
            setShowConformationMail(true);
          }}
        >
          {isLoading ? (
            <div className="animate-spin">
              <LoaderCircle />
            </div>
          ) : (
            <p>SAVE AND DELIVER HERE</p>
          )}
        </Button>
        <Button
          type="button"
          variant="outline"
          onClick={() => {
            setSelectedAddress(null);
            setEditingAddress(false);
            setAddingNewAddress(false);
            setShowConformationMail(false);
          }}
        >
          CANCEL
        </Button>
      </div>
    </div>
  );
};

export default DeliveryAddressForm;
