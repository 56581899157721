import React from "react";
import { Card } from "../ui/card";
import { Button } from "../ui/button";
import { useDispatch } from "react-redux";
import { MinusIcon, PlusIcon } from "lucide-react";
import ROUTES from "../../../src/services/AppConstant";
import { setSelectedBook } from "../../../src/store/orders/reducer";

const BookOrderComponent = ({
  cart,
  bookList,
  handlePostOrder,
  handleAddToCart,
  handleQuantityChange,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="my-5 flex h-[550px] flex-col bg-white p-4">
      <p className="mb-3 text-2xl">Search List</p>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto [&::-webkit-scrollbar]:hidden">
        {bookList?.map((book) => (
          <Card key={book.ISBN10} className="mb-3 p-3">
            <div className="leading-normal">
              <div className="mt-1 flex cursor-pointer items-start justify-between gap-3">
                <div
                  className="leading-5"
                  onClick={() => {
                    window.open(ROUTES?.BOOK_DETAILS, "_blank");
                    dispatch(setSelectedBook(book));
                  }}
                >
                  <p className="mb-[2px] font-semibold leading-tight">
                    {book.Title || "-"}
                  </p>
                  <p className="font-medium">{book.Price || "-"}</p>
                  <div className="flex items-center gap-2">
                    <p className="text-sm">Available:</p>
                    <div>{book.AvailableQty || "-"}</div>
                  </div>
                </div>

                <div className="flex flex-col items-end gap-1">
                  <div className="flex items-center">
                    <div className="flex items-center rounded border">
                      <Button
                        size="icon"
                        variant="ghost"
                        className="h-8 w-8 rounded-none"
                        onClick={() => handleQuantityChange(book, -1)}
                      >
                        <MinusIcon className="h-3 w-3" />
                      </Button>
                      <span className="w-8 text-center text-sm">
                        {cart?.find((item) => item.ISBN10 === book.ISBN10)
                          ?.quantity || 0}
                      </span>
                      <Button
                        size="icon"
                        variant="ghost"
                        className="h-8 w-8 rounded-none"
                        onClick={() => handleQuantityChange(book, 1)}
                      >
                        <PlusIcon className="h-3 w-3" />
                      </Button>
                    </div>
                  </div>

                  <Button
                    onClick={() => handleAddToCart(book)}
                    className="w-full bg-purple-1 hover:bg-purple-700"
                    disabled={book?.AvailableQty === 0}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>

      {cart && cart?.length > 0 && (
        <div className="flex justify-between border-t pt-4">
          <div className="flex items-center gap-2">
            <span className="text-sm font-medium">
              {cart?.length} {cart?.length === 1 ? "item" : "items"} for order
            </span>
          </div>
          <Button
            onClick={handlePostOrder}
            className="bg-purple-1 hover:bg-purple-700"
          >
            Create Order
          </Button>
        </div>
      )}
    </div>
  );
};

export default BookOrderComponent;
