import React from "react";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../../src/services/AppConstant";
import { ShoppingBag, ShoppingCart } from "lucide-react";
import { getFromAsync, SESSION_KEY } from "../../../src/lib/utils";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

const EmptyCart = () => {
  const navigate = useNavigate();
  const currentUserData = getFromAsync(SESSION_KEY?.USER_DATA);

  return (
    <div className="bg-gray-50 p-4 md:p-8">
      <Card className="mx-auto max-w-2xl">
        <CardHeader className={`text-center`}>
          <div className="mx-auto mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-gray-100">
            {currentUserData?.IsLoggedIn ? (
              <ShoppingBag className="h-9 w-9 text-gray-600" />
            ) : (
              <ShoppingCart className="h-9 w-9 text-gray-600" />
            )}
          </div>
          <CardTitle className="text-2xl font-bold text-gray-600">
            {currentUserData?.IsLoggedIn
              ? "No Order Information"
              : "Your Cart is Waiting!"}
          </CardTitle>
        </CardHeader>
        <CardContent className="text-center">
          <p className="mb-6 text-gray-500">
            {currentUserData?.IsLoggedIn
              ? "We couldn't find any order information at the moment. This could be due to a temporary issue or the order might not exist."
              : "Sign in to access the items you saved earlier."}
          </p>
          <Button
            variant="outline"
            className="w-full sm:w-auto"
            onClick={() => {
              currentUserData?.IsLoggedIn
                ? navigate(ROUTES?.ORDER)
                : navigate(ROUTES?.LOGIN);
            }}
          >
            {currentUserData?.IsLoggedIn && (
              <ShoppingCart className="mr-2 h-5 w-5" />
            )}
            {currentUserData?.IsLoggedIn ? "Shop Now" : "Login"}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default EmptyCart;
