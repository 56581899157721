import React from "react";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
} from "../ui/table";

const OrderDetails = ({ order }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const totalAmount = order?.OrderItems.reduce(
    (sum, item) => sum + item.OrderPrice || 0 * item.Qty,
    0,
  );

  const formatCurrency = (amount, currencyCode) => {
    const finalAmount = amount || 0;
    const finalCurrencyCode =
      currencyCode && currencyCode.trim() !== "" ? currencyCode : "USD";

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: finalCurrencyCode,
    }).format(finalAmount);
  };

  return (
    <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
      <div className="space-y-6 p-6">
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <h2 className="text-2xl font-semibold">Order Details</h2>
            <p className="text-sm text-muted-foreground">
              Order #{order.OrderNumber.trim()} - Placed on{" "}
              {formatDate(order.OrderDate)}
            </p>
          </div>
          <div className="text-right">
            <p className="font-semibold">Total Amount</p>
            <p className="text-2xl font-bold text-primary">
              {formatCurrency(totalAmount, order.OrderItems[0].CurrencyCode)}
            </p>
          </div>
        </div>
        <div>
          <h3 className="mb-2 font-semibold">Order Items</h3>
          <div className="h-[300px] overflow-y-auto rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[300px]">Item</TableHead>
                  <TableHead>SKU</TableHead>
                  <TableHead>ISBN-13</TableHead>
                  <TableHead className="text-right">Qty</TableHead>
                  <TableHead className="text-right">Price</TableHead>
                  <TableHead className="text-right">Total</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {order?.OrderItems?.map((item) => (
                  <TableRow key={item.SkuNumber}>
                    <TableCell className="font-medium">{item.Title}</TableCell>
                    <TableCell>{item.SkuNumber}</TableCell>
                    <TableCell>{item.ISBN13}</TableCell>
                    <TableCell className="text-right">{item.Qty}</TableCell>
                    <TableCell className="text-right">
                      {formatCurrency(item.OrderPrice, item.CurrencyCode)}
                    </TableCell>
                    <TableCell className="text-right">
                      {formatCurrency(
                        item.OrderPrice * item.Qty,
                        item.CurrencyCode,
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
