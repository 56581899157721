import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookOrders: [],
  countryList: [],
  selectedBook: null,
  deliveryAddressList: [],
  isAddressListLoading: false,
};

const bookOrdersSlice = createSlice({
  name: "bookOrders",
  initialState,
  reducers: {
    setCountryList: (state, action) => {
      state.countryList = action.payload;
    },
    setBookOrders: (state, action) => {
      state.bookOrders = action.payload;
    },
    setSelectedBook: (state, action) => {
      state.selectedBook = action.payload;
    },
    setDeliveryAddressList: (state, action) => {
      state.deliveryAddressList = action.payload;
    },
    setIsAddressListLoading: (state, action) => {
      state.isAddressListLoading = action.payload;
    },
    clearBookOrders: (state) => {
      state.bookOrders = [];
      state.countryList = [];
      state.selectedBook = null;
      state.deliveryAddressList = [];
      state.isAddressListLoading = false;
    },
  },
});

export const {
  setBookOrders,
  setCountryList,
  clearBookOrders,
  setSelectedBook,
  setDeliveryAddressList,
  setIsAddressListLoading,
} = bookOrdersSlice.actions;
export default bookOrdersSlice;
