"use client";
import React from "react";
import Lottie from "react-lottie";
import * as animationData from "../config/lottie.json";

const LottieSpinner = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="bg-Ellipse_1693 mb-[10px] flex justify-center dark:bg-black">
        <Lottie options={defaultOptions} height={100} width={100} />
      </div>
    </>
  );
};

export default LottieSpinner;
