import { toast } from "react-toastify";
import {
  setCountryList,
  setDeliveryAddressList,
  setIsAddressListLoading,
} from "./reducer";
import { post } from "../../../src/services/ApiService";
import { API_CONSTANT } from "../../../src/services/ApiConstant";
import { getFromAsync, SESSION_KEY } from "../../../src/lib/utils";

export const fetchCountryList = async (dispatch) => {
  const userData = getFromAsync(SESSION_KEY?.USER_DATA);
  const AppUserId = userData && userData.AppUserId;

  post(API_CONSTANT?.COUNTRY_LIST, { AppUserId: AppUserId })
    .then((res) => {
      if (res?.data?.IsError) {
        toast.error(res?.data?.ErrorMessage || res?.message || String(res));
      } else {
        dispatch(setCountryList(res?.data?.CountryList));
      }
    })
    .catch((err) => {
      toast.error(err?.data?.ErrorMessage || err?.message || String(err));
    });
};

export const fetchDeliveryAddressList = async (dispatch) => {
  const userData = getFromAsync(SESSION_KEY?.USER_DATA);
  const AppUserId = userData && userData.AppUserId;

  post(API_CONSTANT?.DELIVERY_ADDRESS_LISTS, { AppUserId: AppUserId })
    .then((res) => {
      dispatch(setIsAddressListLoading(true));
      if (res?.data?.IsError) {
        toast.error(res?.data?.ErrorMessage || res?.message || String(res));
      } else {
        dispatch(setDeliveryAddressList(res?.data));
      }
    })
    .catch((err) => {
      toast.error(err?.data?.ErrorMessage || err?.message || String(err));
    })
    .finally(() => dispatch(setIsAddressListLoading(false)));
};
