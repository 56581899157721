const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  ORDER: "/order",
  CHECK_OUT: "/checkOut",
  ORDER_LIST: "/orderList",
  SEARCH_API: "/searchApi",
  CONTACT_US: "/contactUs",
  BOOK_DETAILS: "/bookDetails",
};
export default ROUTES;
