import Home from "../pages/home";
import Login from "../pages/login";
import SerachBook from "../pages/order";
import ContactUs from "../pages/contactUs";
import SearchApi from "../pages/searchApi";
import ROUTES from "../services/AppConstant";
import BookDetails from "../pages/bookDetails";
import { Routes, Route } from "react-router-dom";
import OrderList from "../pages/order/orderList";
import CheckOutPage from "../pages/order/checkOutPage";

function Navigation() {
  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.ORDER} element={<SerachBook />} />
      <Route path={ROUTES.ORDER_LIST} element={<OrderList />} />
      <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
      <Route path={ROUTES.SEARCH_API} element={<SearchApi />} />
      <Route path={ROUTES.CHECK_OUT} element={<CheckOutPage />} />
      <Route path={ROUTES.BOOK_DETAILS} element={<BookDetails />} />
    </Routes>
  );
}

export default Navigation;
