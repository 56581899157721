import React, { useEffect, useState } from "react";
import {
  fetchCountryList,
  fetchDeliveryAddressList,
} from "../../store/orders/action";
import {
  Card,
  CardTitle,
  CardHeader,
  CardContent,
} from "../../components/ui/card";
import {
  Accordion,
  AccordionItem,
  AccordionContent,
  AccordionTrigger,
} from "../../../src/components/ui/accordion";
import {
  ViewCart,
  EmptyCart,
  DeliveryAddressForm,
} from "../../components/postOrder";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../services/AppConstant";
import { post } from "../../services/ApiService";
import { Input } from "../../components/ui/input";
import { LoaderCircle, Clock } from "lucide-react";
import { Button } from "../../components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { API_CONSTANT } from "../../services/ApiConstant";
import { setBookOrders } from "../../store/orders/reducer";
import { getFromAsync, SESSION_KEY } from "../../lib/utils";

const CheckOutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bookOrders } = useSelector((state) => state);
  const currentUserData = getFromAsync(SESSION_KEY?.USER_DATA);

  const [shipCity, setShipCity] = useState("");
  const [shipState, setShipState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [shipPhoneNo, setShipPhoneNo] = useState("");
  const [shipPinCode, setShipPinCode] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [attachedFile, setAttachedFile] = useState(null);
  const [recipientName, setRecipientName] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [editingAddress, setEditingAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [activeSection, setActiveSection] = useState("summary");
  const [addingNewAddress, setAddingNewAddress] = useState(false);
  const [showAllAddresses, setShowAllAddresses] = useState(false);
  const [selectedShipConutry, setSelectedShipConutry] = useState("");
  const [showConformationMail, setShowConformationMail] = useState(false);

  useEffect(() => {
    if (!currentUserData?.IsLoggedIn) {
      navigate(ROUTES?.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (currentUserData?.IsLoggedIn && bookOrders?.countryList?.length === 0) {
      fetchCountryList(dispatch);
    }
    if (
      currentUserData?.IsLoggedIn &&
      bookOrders?.deliveryAddressList?.length === 0
    ) {
    }
    fetchDeliveryAddressList(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleFileChange = (event) => {
    if (event.target.files) {
      setAttachedFile(event.target.files[0]);
    }
  };

  const calculateTotal = () => {
    return bookOrders?.bookOrders?.reduce((total, item) => {
      const price = parseFloat(item.Price.split(" ")[0]);
      return total + price * item.quantity;
    }, 0);
  };

  const removeItem = (isbn13) => {
    dispatch(
      setBookOrders(
        bookOrders?.bookOrders?.filter((item) => item.ISBN13 !== isbn13),
      ),
    );
  };

  const createNewOrder = () => {
    setIsLoading(true);
    const { AppUserId } = getFromAsync(SESSION_KEY.USER_DATA);

    const orders = bookOrders?.bookOrders?.map((item) => {
      const [price, currency] = item?.Price.split(" ");
      return {
        SkuNumber: item?.SkuNumber,
        Qty: item?.quantity,
        Price: price,
        Currency: currency,
      };
    });

    const formData = new FormData();
    formData.append("AppUserId", AppUserId);
    formData.append("OrderNumber", referenceNumber);
    formData.append("OrderList", JSON.stringify(orders));
    formData.append("DeliveryLabelFilePath", attachedFile);
    formData.append("ShipPhoneNo", selectedAddress?.ShipPhoneNo);
    formData.append("ShipPinCode", selectedAddress?.ShipPinCode);
    formData.append("ShipCityName", selectedAddress?.ShipCityName);
    formData.append("EmailAddress", selectedAddress?.EmailAddress);
    formData.append("ShipStateName", selectedAddress?.ShipStateName);
    formData.append("RecipientName", selectedAddress?.RecipientName);
    formData.append("ShipCountryId", selectedAddress?.ShipCountryId);
    formData.append("ShipAddressLine1", selectedAddress?.ShipAddressLine1);
    formData.append("ShipAddressLine2", selectedAddress?.ShipAddressLine2);
    formData.append("ShipAddressLine3", selectedAddress?.ShipAddressLine3);

    post(API_CONSTANT?.POST_NEW_ORDER, formData)
      .then((res) => {
        if (res?.data?.IsOrderPlaced) {
          toast.success(res?.data?.Message);
          navigate(ROUTES?.ORDER_LIST);
          dispatch(setBookOrders([]));
        }
      })
      .catch((err) => {
        toast.error(err?.data?.ErrorMessage || err?.message || String(err));
      })
      .finally(() => setIsLoading(false));
  };

  const addUpdateAddress = () => {
    setIsLoading(true);
    const { AppUserId } = getFromAsync(SESSION_KEY.USER_DATA);

    const obj = {
      AppUserId: AppUserId,
      ShipCityName: shipCity,
      ShipStateName: shipState,
      ShipPinCode: shipPinCode,
      ShipPhoneNo: shipPhoneNo,
      EmailAddress: emailAddress,
      RecipientName: recipientName,
      ShipAddressLine1: addressLine1,
      ShipAddressLine2: addressLine2,
      ShipAddressLine3: addressLine3,
      CustomerId: selectedAddress?.CustomerId || 1,
      ShipCountryId: selectedShipConutry?.CountryId,
      DeliveryAddressId: selectedAddress
        ? selectedAddress?.DeliveryAddressId
        : 0,
      StatusId: selectedAddress
        ? selectedAddress?.StatusId
        : selectedStatus?.StatusId,
    };

    post(API_CONSTANT?.ADD_UPDATE_DELIVERY_ADDRESS, obj)
      .then((res) => {
        if (res?.data?.IsError) {
          toast.error(res?.data?.ErrorMessage);
        }
        if (res?.data?.IsProcessed) {
          setEditingAddress(false);
          setAddingNewAddress(false);
          setShowConformationMail(true);
          toast.success(res?.data?.ProcessMessage);
          fetchDeliveryAddressList(dispatch);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.ErrorMessage || err?.message || String(err));
      })
      .finally(() => setIsLoading(false));
  };

  const handleQuantityChange = (book, delta) => {
    const updatedCart = bookOrders?.bookOrders?.map((item) => {
      if (item.ISBN10 === book.ISBN10) {
        let newQuantity;

        if (delta === -1) {
          newQuantity = Math.max(item.quantity - 1, 0);
        } else {
          newQuantity = item.quantity + delta;
        }

        return { ...item, quantity: newQuantity };
      }
      return item;
    });

    dispatch(setBookOrders(updatedCart?.filter((item) => item?.quantity > 0)));
  };

  const handleSelectCountry = (selectedValue) => {
    const selectedCountry = bookOrders?.countryList?.find(
      (item) => item?.CountryId === selectedValue?.CountryId,
    );

    setSelectedShipConutry(selectedCountry);
  };

  const handleFetchAddress = async () => {
    setActiveSection("address");
    fetchDeliveryAddressList(dispatch);
  };

  return (
    <div className="container mx-auto max-w-[70%] px-0 !pt-6 text-left sm:p-4">
      <>
        <div className="flex items-center justify-between gap-3">
          <h1 className="mb-6 text-center text-3xl font-bold">Checkout</h1>
          <div
            onClick={() => navigate(ROUTES?.ORDER_LIST)}
            className="group flex cursor-pointer items-center gap-[6px] text-gray-400"
          >
            <Clock className="h-5 w-5 group-hover:text-black-1" />
            <p className="text-center group-hover:text-black-1">
              View past orders
            </p>
          </div>
        </div>

        {bookOrders?.bookOrders?.length !== 0 ? (
          <div className="flex h-auto flex-wrap items-start justify-between gap-5 md:flex-nowrap md:divide-x md:divide-dashed">
            {/* Left Section */}
            <Accordion
              type="single"
              className="w-full"
              value={activeSection}
              onValueChange={setActiveSection}
            >
              <AccordionItem value="summary" className="rounded-lg border">
                <AccordionTrigger
                  showArrow={false}
                  className="px-4 hover:no-underline"
                >
                  ORDER SUMMARY
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveSection((prev) =>
                        prev === "summary" ? null : "summary",
                      );
                    }}
                  >
                    CHANGE
                  </Button>
                </AccordionTrigger>
                <AccordionContent className="px-4">
                  <div className="w-full py-2">
                    <ViewCart
                      removeItem={removeItem}
                      setActiveSection={setActiveSection}
                      handleFetchAddress={handleFetchAddress}
                      handleQuantityChange={handleQuantityChange}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="address" className="rounded-lg border">
                <AccordionTrigger
                  showArrow={false}
                  className="px-4 hover:no-underline"
                >
                  DELIVERY ADDRESS
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveSection((prev) =>
                        prev === "address" ? null : "address",
                      );
                    }}
                  >
                    CHANGE
                  </Button>
                </AccordionTrigger>
                <AccordionContent className="px-4">
                  <div className="w-full py-2">
                    {!editingAddress && !addingNewAddress ? (
                      <>
                        <div>
                          {bookOrders?.deliveryAddressList?.AddressList?.slice(
                            0,
                            showAllAddresses ? undefined : 3,
                          )?.map((address) => {
                            return (
                              <div
                                key={address?.DeliveryAddressId}
                                onClick={() => setSelectedAddress(address)}
                                className="flex cursor-pointer items-start space-x-2 py-2"
                              >
                                <div className="mt-[2px]">
                                  <input
                                    type="radio"
                                    name="deliveryAddress"
                                    id={address?.DeliveryAddressId}
                                    value={address?.DeliveryAddressId}
                                    checked={
                                      selectedAddress?.DeliveryAddressId ===
                                      address?.DeliveryAddressId
                                    }
                                    onChange={() => setSelectedAddress(address)}
                                  />
                                </div>
                                <div className="flex-1">
                                  <div className="flex items-start gap-4">
                                    <div>{address.RecipientName}</div>
                                    <div>{address.ShipPhoneNo}</div>
                                  </div>

                                  <div className="mt-1 text-sm text-muted-foreground">
                                    {[
                                      address.ShipAddressLine1,
                                      address.ShipAddressLine2,
                                      address.ShipAddressLine3,
                                      address.ShipCityName,
                                      address.ShipStateName,
                                      address.ShipPinCode,
                                      address.ShipCountryId,
                                    ]
                                      .filter((part) => part)
                                      .join(", ")}
                                  </div>

                                  {selectedAddress?.DeliveryAddressId ===
                                    address?.DeliveryAddressId && (
                                    <Button
                                      className="mt-4 bg-purple-1 text-white hover:bg-gray-200 hover:font-medium hover:text-purple-1"
                                      onClick={() => {
                                        setShowConformationMail(true);
                                        setActiveSection("showConfirmation");
                                      }}
                                    >
                                      DELIVER HERE
                                    </Button>
                                  )}
                                </div>
                                {selectedAddress?.DeliveryAddressId ===
                                  address?.DeliveryAddressId && (
                                  <Button
                                    size="sm"
                                    variant="outline"
                                    className="ml-auto"
                                    onClick={() =>
                                      setEditingAddress(
                                        address?.DeliveryAddressId,
                                      )
                                    }
                                  >
                                    EDIT
                                  </Button>
                                )}
                              </div>
                            );
                          })}

                          {bookOrders?.deliveryAddressList?.AddressList
                            ?.length > 3 && (
                            <Button
                              variant="link"
                              className="mt-2 text-blue-600"
                              onClick={() =>
                                setShowAllAddresses(!showAllAddresses)
                              }
                            >
                              {showAllAddresses
                                ? "Show less"
                                : `View all ${bookOrders?.deliveryAddressList?.AddressList?.length} addresses`}
                            </Button>
                          )}
                        </div>

                        <div
                          onClick={() => {
                            setSelectedAddress(null);
                            setAddingNewAddress(true);
                          }}
                          className="cursor-pointer border-y-[1px] px-2 py-3"
                        >
                          <p className="text-sm font-semibold transition-colors hover:text-blue-400">
                            + Add a new address
                          </p>
                        </div>
                      </>
                    ) : (
                      <DeliveryAddressForm
                        shipCity={shipCity}
                        isLoading={isLoading}
                        shipState={shipState}
                        bookOrders={bookOrders}
                        shipPhoneNo={shipPhoneNo}
                        shipPinCode={shipPinCode}
                        setShipCity={setShipCity}
                        emailAddress={emailAddress}
                        addressLine1={addressLine1}
                        addressLine2={addressLine2}
                        addressLine3={addressLine3}
                        setShipState={setShipState}
                        attachedFile={attachedFile}
                        recipientName={recipientName}
                        selectedStatus={selectedStatus}
                        setShipPhoneNo={setShipPhoneNo}
                        setShipPinCode={setShipPinCode}
                        referenceNumber={referenceNumber}
                        setEmailAddress={setEmailAddress}
                        selectedAddress={selectedAddress}
                        setAddressLine1={setAddressLine1}
                        setAddressLine2={setAddressLine2}
                        setAddressLine3={setAddressLine3}
                        addUpdateAddress={addUpdateAddress}
                        setRecipientName={setRecipientName}
                        handleFileChange={handleFileChange}
                        setSelectedStatus={setSelectedStatus}
                        setEditingAddress={setEditingAddress}
                        setReferenceNumber={setReferenceNumber}
                        setSelectedAddress={setSelectedAddress}
                        selectedShipConutry={selectedShipConutry}
                        handleSelectCountry={handleSelectCountry}
                        setAddingNewAddress={setAddingNewAddress}
                        showConformationMail={showConformationMail}
                        setSelectedShipConutry={setSelectedShipConutry}
                        setShowConformationMail={setShowConformationMail}
                      />
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>

              {showConformationMail && (
                <div className="mt-4 rounded-lg border bg-white p-4">
                  <p className="mb-2 text-lg font-semibold text-gray-800">
                    Continue to confirm your order
                  </p>
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <label
                        htmlFor="referenceNumber"
                        className="mb-[2px] block text-sm font-medium"
                      >
                        Your reference number (Optional)
                      </label>
                      <Input
                        id="referenceNumber"
                        value={referenceNumber}
                        placeholder="Enter reference number"
                        className="w-full rounded-md border-gray-300"
                        onChange={(e) => setReferenceNumber(e.target.value)}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="fileAttachment"
                        className="mb-[2px] block text-sm font-medium"
                      >
                        Attach Document (Optional)
                      </label>
                      <Input
                        type="file"
                        id="fileAttachment"
                        onChange={handleFileChange}
                        className="w-full rounded-md border-gray-300"
                      />
                    </div>
                  </div>
                  <div className="mt-6 flex justify-end">
                    <Button
                      onClick={() => createNewOrder()}
                      className="rounded-md bg-purple-600 px-6 py-2 text-white hover:bg-purple-700"
                    >
                      {isLoading ? (
                        <div className="animate-spin">
                          <LoaderCircle />
                        </div>
                      ) : (
                        <p>COUNTINUE</p>
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </Accordion>

            <div className="w-full md:w-2/5">
              <Card className="mx-5 w-full max-w-md">
                <CardHeader className="pb-1 pt-3">
                  <CardTitle className="text-base font-medium text-gray-1">
                    PRICE DETAILS
                  </CardTitle>
                </CardHeader>
                <div className="h-px w-full bg-border" />
                <CardContent className="space-y-4 py-3">
                  <div className="space-y-2">
                    <div className="flex items-center justify-between">
                      <span>
                        Price ({bookOrders?.bookOrders?.length} items)
                      </span>
                      <span className="text-base font-semibold text-black-1">
                        ${calculateTotal().toFixed(2)} USD
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Delivery Charges</span>
                      <div className="flex items-center gap-2">
                        <span className="text-muted-foreground line-through">
                          -
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <span>Taxes</span>
                      <div className="flex items-center gap-2">
                        <span className="text-muted-foreground line-through">
                          -
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="h-px w-full border-t border-dashed border-border" />

                  <div className="flex items-center justify-between">
                    <span className="font-medium">Total Payable</span>
                    <span className="text-base font-semibold text-black-1">
                      ${calculateTotal().toFixed(2)} USD
                    </span>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        ) : (
          <EmptyCart />
        )}
      </>
    </div>
  );
};

export default CheckOutPage;
