import axios from "axios";
import { API_CONSTANT } from "./ApiConstant";
import { getFromAsync, SESSION_KEY } from "../lib/utils";

const api = axios.create({
  timeout: 20000,
  baseURL: API_CONSTANT?.BASE_URL,
  httpsAgent: {
    rejectUnauthorized: false,
  },
});

// api.interceptors.request.use(
//   async (_config) => {
//     try {
//       if (_config?.url?.includes("unauthorized")) return _config;
//       if (getFromAsync(SESSION_KEY?.ACCESS_TOKEN)) {
//         _config.headers.Authorization = getAccessToken();
//         // dispatch(setIsAuthenticated(true));
//       }
//       return _config;
//     } catch (error) {}
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

// for multiple requests
// let isRefreshing = false;
// let failedQueue = [];

// const processQueue = (error, token = null) => {
//   failedQueue.forEach((prom) => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve(token);
//     }
//   });

//   failedQueue = [];
// };

// api.interceptors.response.use(undefined, interceptor(api));

export const getToken = async () => {
  return (await getFromAsync(SESSION_KEY.USER_DATA)).ReturnToken;
};

const getHeader = () => {
  return {
    headers: {
      "Content-Type": "*/*",
    },
  };
};

const headerWithToken = async () => {
  const token = await getToken();
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "*/*",
    },
  };
};

const postWithOutToken = (endPoint, data = {}) => {
  const header = getHeader();
  return new Promise((resolve, reject) => {
    api
      .post(`${endPoint}`, data, header)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const post = async (endPoint, data, isHeader = true) => {
  const header = isHeader ? await headerWithToken() : getHeader();
  return new Promise((resolve, reject) => {
    api
      .post(endPoint, data, header)
      .then((res) => {
        if (!res?.data?.IsError && res !== null) {
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { post, postWithOutToken };
