import { useState } from "react";
import { Loader2 } from "lucide-react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { API_CONSTANT } from "../services/ApiConstant";
import { postWithOutToken } from "../services/ApiService";
import { SESSION_KEY, getFromAsync } from "../lib/utils";

const ContactUs = () => {
  const userData = getFromAsync(SESSION_KEY?.USER_DATA);
  const AppUserId = userData && userData.AppUserId;

  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setLoading(true);
    const obj = {
      IPAddress: "",
      Name: data?.name,
      AppUserId: AppUserId,
      PhoneNumber: data?.phone,
      EmailAddress: data?.email,
      QueryInDetail: data?.question,
    };

    postWithOutToken(`${API_CONSTANT?.CONTACT_US}`, obj)
      .then((res) => {
        toast.success(res?.data?.Message);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  return (
    <div className="pt-7 text-left">
      <div className="m-auto max-w-7xl p-[15px]">
        <div className="m-auto md:w-[79.332%]">
          <div className="flex w-full justify-center">
            <p className="text-center text-4xl font-semibold text-dark-gray-1 md:text-[53px]">
              Interested? Let's Talk
            </p>
          </div>
          <div className="mt-[18px]">
            <p className="text-center text-xl text-dark-gray-1 md:text-[22px]">
              Want to discuss more about Spondees, to help sourcing foreign
              books. Let us know your current volumes and arrangements, issues
              faced and we can discuss the advantages we can bring in.
            </p>
          </div>
        </div>
      </div>
      <div className="m-auto max-w-7xl p-[15px]">
        <div className="m-auto max-w-[600px]">
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className="mb-4">
              <label
                className="mb-1 block text-lg font-medium text-gray-700"
                htmlFor="name"
              >
                Your Name:
              </label>
              <p className="mb-6 text-sm font-light">
                Please enter your real name:
              </p>
              <input
                id="name"
                type="text"
                placeholder="Your Name*"
                {...register("name", { required: true })}
                className="focus:shadow-outline h-14 w-full rounded-[6px] bg-lightBlue px-[15px] text-gray-700 shadow-[0_0_0_0] focus:outline-none"
              />
              {errors.name && (
                <p className="text-base text-red-400">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="mb-1 block text-lg font-medium text-gray-700"
                htmlFor="name"
              >
                Your Phone Number:
              </label>
              <p className="mb-6 text-sm">Please enter your Phone Number:</p>
              <input
                id="phone"
                type="tel"
                placeholder="Phone Number*"
                {...register("phone", { required: true })}
                className="focus:shadow-outline h-14 w-full rounded-[6px] bg-lightBlue px-[15px] text-gray-700 shadow-[0_0_0_0] focus:outline-none"
              />
              {errors.phone && (
                <p className="text-base text-red-400">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-lg font-medium text-gray-700"
                htmlFor="name"
              >
                Your Email Address:
              </label>
              <p className="mb-6 text-sm">
                Please carefully check your email address for accuracy
              </p>
              <input
                id="email"
                type="email"
                placeholder="Email Address*"
                {...register("email", { required: true })}
                className="focus:shadow-outline h-14 w-full rounded-[6px] bg-lightBlue px-[15px] text-gray-700 shadow-[0_0_0_0] focus:outline-none"
              />
              {errors.email && (
                <p className="text-base text-red-400">
                  Please fill out this field.
                </p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="mb-2 block text-lg font-medium text-gray-700"
                htmlFor="name"
              >
                Explain your question in details:
              </label>
              <p className="mb-6 text-sm">
                {/* Your OS version, Martex version & build, steps you did. Be VERY
                precise! */}
              </p>
              <textarea
                id="question"
                rows={4}
                placeholder="Our Ecommerce store name is  "
                {...register("question", { required: false })}
                className="focus:shadow-outline w-full rounded-[6px] bg-lightBlue px-3 py-2 text-gray-700 shadow-[0_0_0_0] focus:outline-none"
              ></textarea>
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                disabled={loading}
                className="focus:shadow-outline mt-[15px] rounded bg-blue-600 px-10 py-3 text-[1.2rem] text-white hover:bg-blue-700 focus:outline-none"
              >
                {loading ? (
                  <Loader2 className="mx-5 h-6 w-6 animate-spin" />
                ) : (
                  "Submit Request"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
