import React from "react";
import { ConfirmOrderList } from "../../../src/components/postOrder";

const OrderList = () => {
  return (
    <div className="container mx-auto h-full w-full pt-3">
      <ConfirmOrderList />
    </div>
  );
};

export default OrderList;
