import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ROUTES from "./services/AppConstant";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Navigation from "./components/Navigation";
import ScrollToTop from "./components/ScrollToTop";
import { store } from "./store/store";
import { Provider } from "react-redux";

function App() {
  const location = useLocation();

  // useEffect(() => {
  //   if (location?.pathname !== ROUTE.BOOK_SEARCH) {
  //     context.setSearch("");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  // useEffect(() => {
  //   checkUserLogin();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const checkUserLogin = async () => {
  //   const user = await getFromAsync(SESSION_KEY.USER_DATA);
  //   if (user?.GetNewPassword !== undefined && !user?.GetNewPassword) {
  //     if (location.pathname === ROUTE?.LOGIN) {
  //       navigate(ROUTE?.DASHBOARD);
  //     }
  //   } else {
  //     navigate(ROUTE?.LOGIN);
  //   }
  // };

  return (
    <div className="App">
      <ToastContainer />
      <Provider store={store}>
        <div className="w-full">
          {location?.pathname !== ROUTES?.LOGIN && <Header />}
          <Navigation />
          {location?.pathname !== ROUTES?.LOGIN && <Footer />}
          {location?.pathname !== ROUTES?.LOGIN && <ScrollToTop />}
        </div>
      </Provider>
    </div>
  );
}

export default App;
