import { ChevronRight } from "lucide-react";
import ROUTES from "../services/AppConstant";
import { ABOUTUS_ARRAY, FEATURES_ARRAY, QUESTION_ARRY } from "../lib/utils";

export default function Home() {
  return (
    <div>
      <section id="home">
        <div className="mb-[50px] h-full w-full overflow-x-hidden bg-home-bg px-[15px] xl:px-0">
          <div className="m-auto flex max-w-7xl flex-col items-center pt-10 sm:relative sm:flex-row sm:pt-20">
            <div className="flex h-full w-full flex-wrap items-start break-words sm:min-w-[630px] md:px-0 lg:pr-[20px]">
              <div className="mb-[20px]">
                <p className="relative mb-[6px] w-full text-center text-[25px] font-bold leading-normal text-white sm:text-start sm:text-[3.80rem]">
                  Source international books for your customers through us.
                </p>
                <p className="text-center text-sm text-white sm:text-start sm:text-lg lg:text-2xl">
                  E-commerce websites can now source over 15 million titles from
                  2,500 vendors across more than 5 countries.
                </p>
              </div>
              <div className="mb-[40px] mt-1 flex justify-center">
                <div className="flex space-x-16 text-white">
                  <div className="flex flex-col items-center justify-center text-center sm:flex-row sm:items-start">
                    <div className="flex items-end text-center">
                      <div className="text-3xl font-bold leading-none md:text-5xl">
                        3
                      </div>
                      <div className="font-bold leading-none md:text-[2rem]">
                        x
                      </div>
                    </div>
                    <div className="pl-1 text-lg">Shorter delivery time</div>
                  </div>
                  <div className="flex flex-col items-center justify-center text-center sm:flex-row sm:items-start">
                    <div className="text-3xl font-bold md:text-5xl">1000%</div>
                    <div className="pl-1 text-lg">
                      Large selection for your home customers
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-5 flex w-full justify-center sm:mb-0 md:justify-start">
                <a
                  href={ROUTES?.SEARCH_API}
                  className="rounded-[4px] border-2 border-blue-500 bg-blue-500 px-[30px] py-[10px] text-white hover:border-2 hover:border-white hover:bg-transparent"
                >
                  <p className="text-lg">SEARCH API</p>
                </a>
              </div>
            </div>
            <div className="h-full w-full">
              <img
                src="/images/home/tablet-01.png"
                alt="tablet"
                className="w-auto sm:max-w-[1300px]"
                height={770}
                width={1200}
              />
            </div>
          </div>
        </div>
      </section>

      {/* ----section2---- */}
      <section id="features">
        <div className="pt-[50px]">
          <div className="m-auto max-w-7xl">
            <div className="flex justify-center">
              <div className="text-center">
                <div className="text-[1.1rem] font-semibold uppercase tracking-wider text-gray-700">
                  Endless Possibilities
                </div>
                <h1 className="l mt-[30px] max-w-[700px] text-3xl font-semibold text-gray-900 md:text-[46px]">
                  Source books from around the world
                </h1>
                <p className="mt-4 text-xl text-gray-600 md:text-[24px]">
                  We operate 24/7 across multiple time zones
                </p>
              </div>
            </div>

            <div className="md:mt-[60px]">
              <div className="flex w-full flex-wrap items-start justify-center">
                {FEATURES_ARRAY?.map((list, i) => {
                  return (
                    <div
                      className="mb-4 flex flex-col items-center justify-center px-2 sm:w-1/2 md:flex-row md:items-start"
                      key={i}
                    >
                      <div className="h-full">
                        <img
                          src={list?.icon}
                          alt=""
                          height={100}
                          width={100}
                          className="min-h-[135px] min-w-[135px]"
                        />
                      </div>
                      <div>
                        <div className="mb-[10px] text-center text-[25px] font-bold text-black-1 md:text-start">
                          {list?.title}
                        </div>
                        <div className="text-dark-gray text-center text-[1rem] md:text-start">
                          {list?.description}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ----section3---- */}

      <div className="m-auto mt-[50px] max-w-7xl rounded-xl bg-home-section2">
        <div className="px-[15px] pb-[80px] pt-[80px] lg:px-[70px]">
          <div className="relative m-auto flex max-w-7xl flex-col-reverse items-center justify-center md:flex-row">
            <div className="sm:w-1/2">
              <p className="mb-[35px] text-[15px] uppercase text-dark-gray-1">
                A System That Works
              </p>
              <div>
                <p className="mb-[26px] text-3xl text-dark-gray-1 sm:text-4xl xl:text-5xl">
                  Over 1 million books shipped to more than 50 countries every
                  year.
                </p>
              </div>
              <div>
                <div className="mb-[18px] flex items-start">
                  <div className="mt-[8px] min-h-[6px] min-w-[6px] rounded-full bg-dark-gray-1" />
                  <p className="ml-[15px]">
                    Join us and become part of a system that handles all your
                    orders from around the globe.
                  </p>
                </div>
                <div className="mb-[18px] flex items-start">
                  <div className="mt-[8px] min-h-[6px] min-w-[6px] rounded-full bg-dark-gray-1" />
                  <p className="ml-[15px]">
                    This is an invite-only service, where we exclusively partner
                    with one or two stores in underrepresented countries.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <img
                src="/images/home/strategyBanner.png"
                alt=""
                height={652}
                width={575}
                className="xl:max-w-[675px]"
              />
            </div>
          </div>
        </div>
      </div>

      {/* ----section4---- */}

      <div className="m-auto mt-[100px] max-w-7xl rounded-xl pb-[85px]">
        <div className="relative m-auto flex max-w-7xl flex-col-reverse items-center justify-center sm:flex-row">
          <div>
            <img
              src="/images/home/engageBanner.png"
              alt=""
              height={652}
              width={575}
              className="xl:max-w-[675px]"
            />
          </div>
          <div className="ml-[30px] sm:w-[80%] lg:w-1/2">
            <p className="mb-[35px] text-[15px] uppercase text-dark-gray-1">
              Organized Sourcing
            </p>
            <div>
              <p className="mb-[26px] text-3xl text-dark-gray-1 sm:text-4xl lg:text-5xl">
                Easily connect your e-commerce store with our APIs
              </p>
              <p className="mb-[1rem] text-lg text-dark-gray-1">
                It's all about bookselling. Discuss and set up an account to
                access our APIs—it's fast and easy.
              </p>
            </div>
            <div>
              <p className="mb-[20px] text-[27px] font-bold text-dark-gray-1">
                Get More Done in Less Time
              </p>
              <div className="mb-[18px] flex items-start">
                <div className="mt-[8px] min-h-[6px] min-w-[6px] rounded-full bg-dark-gray-1" />
                <p className="ml-[15px]">
                  Search API - Initiate a search with our API when no results
                  are found, or run a parallel search. Provide your customers
                  with real-time price and availability information.
                </p>
              </div>
              <div className="mb-[18px] flex items-start">
                <div className="mt-[8px] min-h-[6px] min-w-[6px] rounded-full bg-dark-gray-1" />
                <p className="ml-[15px] text-dark-gray-1">
                  Order API - Keep submitting your orders with your order number
                  to receive consolidated deliveries as quickly as every day.
                </p>
              </div>
              <div className="mb-[18px] flex items-start">
                <div className="mt-[8px] min-h-[6px] min-w-[6px] rounded-full bg-dark-gray-1" />
                <p className="ml-[15px] text-dark-gray-1">
                  No email ordering. No form submissions. No hassle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----How it work section---- */}
      <div className="relative m-auto max-w-7xl pb-[80px]">
        <div className="flex justify-center p-[8px] md:p-[15px]">
          <div>
            <p className="text-center text-4xl font-semibold text-dark-gray-1 md:text-[2.875rem]">
              How It Works?
            </p>
            <div className="mt-[18px] flex w-full justify-center">
              <p className="text-center text-[1.3125rem] text-dark-gray-1 sm:w-[60%]">
                For example, let's consider Austria as your home country. An
                e-commerce store looking to source foreign books can easily have
                them delivered to their fulfillment hub in Vienna.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-[40px] flex flex-wrap lg:mt-[75px]">
          <div className="mb-[30px] flex-[0,0,auto] justify-center px-2 text-center sm:w-[50%] lg:w-[33.33%]">
            <div className="mx-[8px] mb-[60px] flex justify-center rounded-t-[12px] bg-gradient-purple pt-[42px]">
              <img
                src="/images/home/howitwork1.png"
                alt=""
                className="w-auto sm:max-h-[200px]"
                height={175}
                width={272}
              />
            </div>
            <div className="text-center">
              <h6 className="mb-[15px] text-[25px] font-bold text-dark-gray-1">
                Foreign Books to Austria
              </h6>
              <p className="text-lg text-dark-gray-1">
                Foreign books refer to titles published outside Austria. The
                Search API provides a list of available books, and the bookstore
                continuously places orders through the API.
              </p>
            </div>
          </div>
          <div className="mb-[30px] flex-[0,0,auto] justify-center px-2 text-center sm:w-[50%] lg:w-[33.33%]">
            <div className="mx-[8px] mb-[60px] flex justify-center rounded-t-[12px] bg-gradient-purple pt-[42px]">
              <img
                src="/images/home/howitwork2.jpg"
                alt=""
                className="w-auto sm:max-h-[200px]"
                height={175}
                width={272}
              />
            </div>
            <div className="text-center">
              <h6 className="mb-[15px] text-[25px] font-bold text-dark-gray-1">
                International Priority Delivery
              </h6>
              <p className="text-lg text-dark-gray-1">
                Books sourced from various shipping countries are packed and
                boxed for delivery to Vienna.
              </p>
            </div>
          </div>
          <div className="mb-[30px] flex-[0,0,auto] justify-center px-2 text-center sm:w-[50%] lg:w-[33.33%]">
            <div className="mx-[8px] mb-[60px] flex justify-center rounded-t-[12px] bg-gradient-purple pt-[42px]">
              <img
                src="/images/home/howitwork3.jpg"
                alt=""
                className="w-auto sm:max-h-[200px]"
                height={175}
                width={272}
              />
            </div>
            <div className="text-center">
              <h6 className="mb-[15px] text-[25px] font-bold text-dark-gray-1">
                Prepacked Orders
              </h6>
              <p className="text-lg text-dark-gray-1">
                Submit your 4x6 thermal shipping label, and we'll label the
                package for you. Delivery frequency can be adjusted based on
                your order volume.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* ---- About us ---- */}
      <section id="about">
        <div className="md:py-[85px]">
          <div className="relative m-auto flex flex-col sm:max-w-7xl lg:flex-row">
            <div className="w-full sm:w-[42%] sm:min-w-[550px]">
              <div className="relative flex w-full flex-wrap items-center p-[15px]">
                <div className="mb-[20px]">
                  <h2 className="mb-[50px] text-3xl font-bold text-dark-gray-1 md:text-[53px]">
                    About us
                  </h2>
                  <p className="mb:text-[24px] mb-[18px] text-xl text-dark-gray-1">
                    We are a family-owned business with over 50 years of
                    bookselling experience. Our well- established processes and
                    systems have been refined over the years and are now
                    accessible through our APIs.
                  </p>
                  <div className="mb-[18px] flex items-start">
                    <div className="mt-[8px] min-h-[6px] min-w-[6px] rounded-full bg-dark-gray-1" />
                    <p className="ml-[15px] text-lg text-dark-gray-1">
                      With an existing presence in five major markets, we
                      continue to expand into new countries and partner with
                      more publishers each year.
                    </p>
                  </div>
                </div>
                <div>
                  <a href={ROUTES?.SEARCH_API} className="">
                    <div className="flex items-center">
                      <p className="text-[18px] text-blue-600">
                        Get started - Let us connect{" "}
                      </p>
                      <div>
                        <ChevronRight className="ml-3 pt-1 text-blue-600" />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              {ABOUTUS_ARRAY?.map((list, i) => {
                return (
                  <div className="px-[10px] md:w-1/2 xl:px-[13px]" key={i}>
                    <div className="mb-[30px] rounded-[12px] bg-white p-[25px] text-start shadow-AboutUs xl:p-[35px]">
                      <div className="flex h-full justify-center sm:justify-start">
                        <img
                          src={list?.icon}
                          alt=""
                          height={100}
                          width={100}
                          className="min-h-[135px] min-w-[135px]"
                        />
                      </div>
                      <div>
                        <div className="mb-[10px] text-center text-[25px] font-bold text-black-1 sm:text-start">
                          {list?.title}
                        </div>
                        <div className="text-dark-gray text-center text-[1rem] sm:text-start">
                          {list?.description}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* ----Quesions ---- */}
      <section id="question-and-answer">
        <div className="m-auto max-w-7xl px-[15px] pt-9 md:pt-[85px] xl:px-0">
          <div className="mb-[70px] flex w-full justify-center">
            <div>
              <p className="text-center text-4xl font-bold text-dark-gray-1 md:text-[3.125rem]">
                Questions & Answers
              </p>
              <p className="mt-[18px] w-full text-center text-[24px] text-light-gray-1">
                Check if your question is answered below. If not, feel free to
                <a href={ROUTES?.CONTACT_US} className="text-blue-600">
                  {" "}
                  contact us.
                </a>
              </p>
            </div>
          </div>
          <div className="flex w-full flex-wrap gap-[30px] text-left">
            {QUESTION_ARRY?.map((list, i) => {
              return (
                <div className="flex flex-wrap lg:w-[48%]" key={i}>
                  <div className="flex flex-col">
                    <p className="mb-[18px] text-xl text-dark-gray-1 md:text-[25px]">
                      {list?.count}. {list?.question}
                    </p>
                    <p className="mb-[18px] text-base text-light-gray-1 md:text-lg">
                      {list?.answer}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="mt-[40px] flex justify-center">
            <div className="flex rounded-[100px] bg-sky px-[20px] py-[22px] md:px-[46px]">
              <div className="text-[18px]">Any questions?</div>
              <a
                href={ROUTES?.CONTACT_US}
                className="pl-1 text-[18px] text-blue-600"
              >
                Get in Touch
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
