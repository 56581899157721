import React, { useEffect, useState } from "react";
import {
  Truck,
  Package2,
  PlusIcon,
  MinusIcon,
  AlertCircle,
  LoaderCircle,
  ShoppingCart,
} from "lucide-react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "../../../src/components/ui/card";
import ROUTES from "../../../src/services/AppConstant";
import { Badge } from "../../../src/components/ui/badge";
import { Button } from "../../../src/components/ui/button";
import { API_CONSTANT } from "../../../src/services/ApiConstant";
import { setBookOrders } from "../../../src/store/orders/reducer";
import { SESSION_KEY, getFromAsync } from "../../../src/lib/utils";
import { postWithOutToken } from "../../../src/services/ApiService";

const BookDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bookOrders } = useSelector((state) => state);
  const { AppUserId } = getFromAsync(SESSION_KEY?.USER_DATA);

  const [loading, setLoading] = useState(false);
  const [bookDetailsData, setBookDetailsData] = useState(null);

  const price = bookDetailsData?.Price?.trim() || "";
  const [amount, currency] = price ? price.split(/\s+/) : ["0", "USD"];

  const currentBook = bookOrders?.bookOrders?.find(
    (item) => item.ISBN10 === bookDetailsData?.ISBN10,
  );
  const quantity = currentBook?.quantity || 1;

  useEffect(() => {
    getBookDetailsApi(bookOrders?.selectedBook?.SkuNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookOrders?.selectedBook]);

  const getBookDetailsApi = (SkuNumber) => {
    setLoading(true);
    const obj = {
      BookId: 0,
      IPAddress: "",
      AppUserId: AppUserId,
      SkuNumber: SkuNumber,
    };
    postWithOutToken(`${API_CONSTANT?.BOOK_DETAILS}`, obj)
      .then((res) => {
        setBookDetailsData(res?.data?.Book);
        if (res?.data?.IsError || res?.data?.IsError === true) {
          toast.error(res?.data?.ErrorMessage);
        }
      })
      .catch((err) => {
        toast.error(err?.data?.ErrorMessage || err?.message || String(err));
      })
      .finally(() => setLoading(false));
  };

  const DetailRow = ({ label, value }) => {
    return (
      <div className="flex flex-col">
        <dt className="text-muted-foreground">{label}</dt>
        <dd className="font-medium">{value}</dd>
      </div>
    );
  };

  const handleAddToCart = (book) => {
    const existingItem = bookOrders?.bookOrders?.find(
      (item) => item.ISBN10 === book.ISBN10,
    );

    if (existingItem) {
      const updatedCart = bookOrders.bookOrders.map((item) =>
        item.ISBN10 === book.ISBN10 ? { ...item, quantity } : item,
      );
      dispatch(setBookOrders(updatedCart));
    } else {
      dispatch(
        setBookOrders([...bookOrders.bookOrders, { ...book, quantity }]),
      );
    }
  };

  const handleQuantityChange = (book, delta) => {
    const newQuantity = quantity + delta;

    if (newQuantity < 1) {
      return;
    }

    const existingItem = bookOrders?.bookOrders?.find(
      (item) => item.ISBN10 === book.ISBN10,
    );

    if (existingItem) {
      const updatedCart = bookOrders.bookOrders.map((item) =>
        item.ISBN10 === book.ISBN10 ? { ...item, quantity: newQuantity } : item,
      );
      dispatch(setBookOrders(updatedCart));
    } else {
      dispatch(
        setBookOrders([
          ...bookOrders.bookOrders,
          { ...book, quantity: newQuantity },
        ]),
      );
    }

    setBookDetailsData((prevData) => ({
      ...prevData,
      quantity: newQuantity,
    }));
  };

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center">
        <LoaderCircle className="h-10 w-10 animate-spin" />
      </div>
    );
  }

  if (!bookDetailsData || bookDetailsData === null) {
    return (
      <div className="flex min-h-screen flex-col items-center justify-center px-4 text-center">
        <AlertCircle className="mb-4 h-16 w-16 text-destructive" />
        <h1 className="mb-2 text-2xl font-bold">Oops! Book Not Found</h1>
        <p className="mb-4 text-muted-foreground">
          We couldn't find the book you're looking for. It might be temporarily
          unavailable or no longer in our catalog.
        </p>
        <Button onClick={() => navigate(ROUTES?.ORDER)}>
          Return to Book List
        </Button>
      </div>
    );
  }

  return (
    <div className="mb-[30px] h-full w-full pb-[50px] pt-5">
      <div className="container mx-auto px-4 py-8">
        <div className="grid gap-8 lg:grid-cols-2">
          {/* Left Column - Image */}
          <div className="flex flex-col items-center gap-4">
            <Card className="w-full max-w-md p-6">
              <img
                width={400}
                height={600}
                alt="Book Cover"
                className="w-full rounded-lg object-cover"
                src="/icons/book/book_placeholder.svg?height=600&width=400"
              />
            </Card>
            {bookDetailsData?.SkuNumber && (
              <div className="flex items-center gap-2 text-muted-foreground">
                <Package2 className="h-4 w-4" />
                <span>SKU: {bookDetailsData?.SkuNumber}</span>
              </div>
            )}
          </div>

          {/* Right Column - Details */}
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <div>
                <h1 className="mb-2 text-2xl font-bold lg:text-3xl">
                  {bookDetailsData?.Title}
                </h1>
                {bookDetailsData?.Author && (
                  <p className="text-lg text-muted-foreground">
                    By {bookDetailsData?.Author}
                  </p>
                )}
              </div>

              {bookDetailsData?.Price && (
                <div className="flex items-baseline gap-4">
                  <span className="text-3xl font-bold">
                    ${bookDetailsData?.Price || 0}
                  </span>
                </div>
              )}
              {bookDetailsData?.AvailabilityDays && (
                <div className="flex items-center gap-2 text-emerald-600">
                  <Truck className="h-4 w-4" />
                  <span>{bookDetailsData?.AvailabilityDays}</span>
                </div>
              )}
              {bookDetailsData?.AvailableQty && (
                <Badge variant="secondary" className="w-fit">
                  {bookDetailsData?.AvailableQty} copies available
                </Badge>
              )}
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <div className="flex items-center rounded-md border">
                  <Button
                    size="icon"
                    variant="ghost"
                    className="rounded-none"
                    disabled={quantity <= 1}
                    onClick={() => handleQuantityChange(bookDetailsData, -1)}
                  >
                    <MinusIcon className="h-5 w-5" />
                  </Button>
                  <div className="w-12 text-center font-medium">{quantity}</div>
                  <Button
                    size="icon"
                    variant="ghost"
                    className="rounded-none"
                    onClick={() => handleQuantityChange(bookDetailsData, 1)}
                  >
                    <PlusIcon className="h-5 w-5" />
                  </Button>
                </div>
                <Button
                  className="gap-2"
                  onClick={() => handleAddToCart(bookDetailsData)}
                >
                  <ShoppingCart />
                  Add to Cart
                </Button>
              </div>
              <div className="text-sm text-muted-foreground">
                Total: ${(parseFloat(amount) * quantity).toFixed(2)} {currency}
              </div>
            </div>

            <div className="h-px w-full bg-border" />
            {/* Book Details */}
            <div className="grid gap-4">
              <h2 className="text-xl font-semibold">Book Details</h2>
              <div className="grid grid-cols-1 gap-4 text-sm md:grid-cols-2">
                {bookDetailsData?.ISBN10 && (
                  <DetailRow label="ISBN-10" value={bookDetailsData?.ISBN10} />
                )}
                {bookDetailsData?.ISBN13 && (
                  <DetailRow label="ISBN-13" value={bookDetailsData?.ISBN13} />
                )}
                {bookDetailsData?.Binding && (
                  <DetailRow label="Binding" value={bookDetailsData?.Binding} />
                )}
                {bookDetailsData?.Language && (
                  <DetailRow
                    label="Language"
                    value={bookDetailsData?.Language}
                  />
                )}
                {bookDetailsData?.BookWeight && (
                  <DetailRow
                    label="Weight"
                    value={bookDetailsData?.BookWeight}
                  />
                )}
                {bookDetailsData?.DateOfPublication && (
                  <DetailRow
                    label="Publication Date"
                    value={bookDetailsData?.DateOfPublication}
                  />
                )}
                {bookDetailsData?.CountryOfOrigin && (
                  <DetailRow
                    label="Country of Origin"
                    value={bookDetailsData?.CountryOfOrigin}
                  />
                )}
                {bookDetailsData?.Page && (
                  <DetailRow label="Pages" value={bookDetailsData?.Page} />
                )}
                {bookDetailsData?.Publisher && (
                  <DetailRow
                    label="Publisher"
                    value={bookDetailsData?.Publisher}
                  />
                )}
                {bookDetailsData?.PlaceOfPublication && (
                  <DetailRow
                    label="Place of Publication"
                    value={bookDetailsData?.PlaceOfPublication}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookDetails;
