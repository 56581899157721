import React from "react";
import { Button } from "../ui/button";
import { CardContent } from "../ui/card";
import { useDispatch, useSelector } from "react-redux";
import ROUTES from "../../../src/services/AppConstant";
import { Minus, Plus, Trash2, Truck } from "lucide-react";
import { calculateDateRange } from "../../../src/lib/utils";
import { setSelectedBook } from "../../../src/store/orders/reducer";

const ViewCart = ({ handleFetchAddress, handleQuantityChange, removeItem }) => {
  const dispatch = useDispatch();
  const { bookOrders } = useSelector((state) => state);

  return (
    <div>
      <CardContent className="max-h-[550px] min-h-[200px] overflow-y-auto !px-0 [&::-webkit-scrollbar]:hidden">
        {bookOrders?.bookOrders?.length === 0 ? (
          <p>Your card is empty</p>
        ) : (
          bookOrders?.bookOrders?.map((item, ind) => (
            <div key={ind} className="mb-4 rounded border p-4">
              <div>
                <h3
                  onClick={() => {
                    dispatch(setSelectedBook(item));
                    window.open(ROUTES?.BOOK_DETAILS, "_blank");
                  }}
                  className="cursor-pointer font-semibold"
                >
                  {item?.Title || "-"}
                </h3>
                <p className="mb-[2px]">Price: {item.Price || "-"}</p>

                {item.AvailabilityDays && (
                  <div className="mb-[2px] flex items-center gap-2 text-sm">
                    <Truck className="h-4 w-4" />
                    <span>
                      Delivery by{" "}
                      {
                        calculateDateRange(item.AvailabilityDays)
                          ?.formattedEarliestDate
                      }{" "}
                      -{" "}
                      {
                        calculateDateRange(item.AvailabilityDays)
                          ?.formattedLatestDate
                      }
                    </span>
                  </div>
                )}
                <div className="mb-2 flex items-center justify-between gap-2">
                  <div className="flex items-center rounded-lg border-[1px] border-dashed bg-background p-1">
                    <Button
                      size="icon"
                      variant="ghost"
                      className="h-7 w-7 rounded-md hover:bg-muted"
                      onClick={() => handleQuantityChange(item, -1)}
                      disabled={
                        bookOrders?.bookOrders?.find(
                          (ele) => ele.ISBN10 === item.ISBN10,
                        )?.quantity === 1
                      }
                    >
                      <Minus className="h-4 w-4" />
                      <span className="sr-only">Decrease quantity</span>
                    </Button>
                    <div className="flex w-10 items-center justify-center font-medium tabular-nums">
                      {item.quantity}
                    </div>
                    <Button
                      size="icon"
                      variant="ghost"
                      className="h-7 w-7 rounded-md hover:bg-muted"
                      onClick={() => handleQuantityChange(item, 1)}
                    >
                      <Plus className="h-4 w-4" />
                      <span className="sr-only">Increase quantity</span>
                    </Button>
                  </div>
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => removeItem(item.ISBN13)}
                    aria-label={`Remove ${item.Title} from cart`}
                    className="group transition-colors duration-200 ease-in-out hover:bg-red-50"
                  >
                    <Trash2 className="h-4 w-4 transition-colors duration-200 ease-in-out group-hover:text-red-500" />
                  </Button>
                </div>
                <div className="border-t pt-2 text-end text-sm font-medium">
                  Total: ${parseFloat(item.Price.split(" ")[0]).toFixed(2)} ×{" "}
                  {item.quantity} = $
                  {(
                    parseFloat(item.Price.split(" ")[0]) * item.quantity
                  ).toFixed(2)}
                </div>
              </div>
            </div>
          ))
        )}
      </CardContent>
      <div className="sticky bottom-0 bg-white p-4 text-end shadow-md">
        <Button
          onClick={handleFetchAddress}
          className="w-[200px] bg-purple-1 text-base text-white hover:bg-gray-200 hover:text-purple-1"
        >
          PLACE ORDER
        </Button>
      </div>
    </div>
  );
};

export default ViewCart;
