// const API_URL = "http://api.spondees.com";
const API_URL = "https://api.spondees.com";

const API_CONSTANT = {
  BASE_URL: `${API_URL}/api`,
  LOGIN: "/Login",
  CONTACT_US: "/ContactUS",
  BOOK_SEARCH: "/BookSearch",
  STATS_GROUP: "/StatsGroup",
  BOOK_DETAILS: "/BookDetail",
  COUNTRY_LIST: "/CountryList",
  ORDER_STATUS: "/OrderStatus",
  ORDER_DETAIL: "/OrderDetail",
  CONFIRM_ORDER_LIST: "/OrderList",
  POST_NEW_ORDER: "/Order/NewOrder",
  DELIVERY_ADDRESS_LISTS: "/DeliveryAddressList",
  ADD_UPDATE_DELIVERY_ADDRESS: "/AddUpdate_DeliveryAddress",
};
export { API_CONSTANT };
