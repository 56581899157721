import React from "react";
const Spinner = ({ spinnerClass }) => {
  return (
    <div>
      <div className="relative flex h-16 w-full items-center justify-center">
        {[...Array(12)].map((_, i) => (
          <div
            key={i}
            className={`${spinnerClass} animate-fade absolute h-3 w-3 rounded-full bg-white`}
            style={{
              transform: `rotate(${i * 30}deg) translate(30px)`,
              animationDelay: `${i * 0.1}s`,
            }}
          />
        ))}
      </div>
      <div className="mt-[62px] flex w-full items-center justify-center text-xl text-white">
        Please wait
      </div>
    </div>
  );
};
export default Spinner;
