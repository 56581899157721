import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { addDays, format } from "date-fns";
import ROUTES from "../services/AppConstant";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const getFromAsync = (key) => JSON.parse(localStorage.getItem(key));
export const removeFromAsync = async (key) => localStorage.removeItem(key);
export const setInAsyncStorage = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));

export const calculateDateRange = (availabilityDays = 0) => {
  const baseDate = new Date();
  const daysToAdd = Number(availabilityDays) || 0;

  const earliestDate = addDays(baseDate, daysToAdd + 5);
  const latestDate = addDays(baseDate, daysToAdd + 7);

  const formattedEarliestDate = format(earliestDate, "EEE MMM d");
  const formattedLatestDate = format(latestDate, "EEE MMM d");

  return { formattedEarliestDate, formattedLatestDate };
};

export const SESSION_KEY = {
  USER_DATA: "USER_INFO",
};

export const FEATURES_ARRAY = [
  {
    title: "Catalog Updates",
    description:
      "New books are continuously added, sourced from newsletters, book reviews, publisher catalogs,metadata feeds, and vendor inventories, to name a few. Updates are also made regularly to existinglistings. We don't just focus on English titles—we cover all genres and much more.",
    icon: "/icons/home/Shape4.svg",
  },
  {
    title: "Customer Experience",
    description:
      "Avoid 'Book not found' or 'Out of stock' messages. Integrate our Search API to display exactly what your customers are looking for.",
    icon: "/icons/home/Shape.svg",
  },
  {
    title: "Single Price",
    description:
      "You only pay the displayed price in your home currency. Prices and availability get continuously updated from multiple sources in real-time.",
    icon: "/icons/home/Shape2.svg",
  },
  {
    title: "We Deliver",
    description:
      "Beyond just prices and discounts, we handle consolidated deliveries to your home country using multiple carriers, as well as the final delivery to your customers.",
    icon: "/icons/home/Shape3.svg",
  },
  {
    title: "Save Time and Costs",
    description:
      "Leave the backend work for us and focus on your customers. And let us work together to understand and meet customer requirements.",
    icon: "/icons/home/Shape.svg",
  },
  {
    title: "Just Search and Order API - That's It",
    description:
      "No need for multiple integrations with various vendors and complex algorithms. We've created a single catalog for you. You can even backorder unavailable books.",
    icon: "/icons/home/Shape4.svg",
  },
];

export const QUESTION_ARRY = [
  {
    count: 1,
    question: "Why is this service invite-only?",
    answer:
      "This is a trade service where we choose to partner with bookstores in underserved countries. Our partners can be established stores or new startups.",
  },
  {
    count: 2,
    question: "How can we use the search results?",
    answer:
      "The search results provide pricing and availability information, along with other identification details. This is not a metadata service; you are expected to build your own catalog. Search result metadata is not intended for storage.",
  },
  {
    count: 3,
    question: "Do you monitor usage?",
    answer:
      "Yes, we monitor both usage and sales patterns. Typically, sales and usage are correlated. If discrepancies arise, we work to identify and resolve the underlying issues.",
  },
  {
    count: 4,
    question: "How is pricing determined?",
    answer:
      "We collaborate with you to set our selling prices, which can be in USD or your local currency. You can then add your markup before presenting the final prices to your customers. Depending on your location, our nearest office will handle shipping, invoicing, and other logistics.",
  },

  {
    count: 5,
    question: "Is it necessary to have the books pre-packed?",
    answer:
      "We highly recommend individual packing to ensure the books remain secure during transit. It also reduces the need for extra packing materials that are often discarded.",
  },

  {
    count: 6,
    question: "I already work with some vendors. Can I continue with them?",
    answer:
      "Absolutely! You can continue working with your current vendors while comparing our services over a few months. It’s not necessary to source all foreign books through us. The usage and sales data will guide the best approach moving forward.",
  },
  {
    count: 7,
    question: "Why do you work with only one or two customers per country?",
    answer:
      "We aim to maintain high volumes with a select few stores to achieve economies of scale. This approach also prevents business advantages from being diluted among too many customers. If the market justifies it, we may expand our service to more customers with mutual agreement.",
  },
  {
    count: 8,
    question: "Are you a technology company?",
    answer:
      "No, we are a bookstore. While we embrace new technologies and have self-developed systems,our focus remains on bookselling. We are self funded, so our priority is doing what's best for the book trade, not meeting investor targets.",
  },
  {
    count: 9,
    question: "What if I find an error in the catalog?",
    answer: "Simply submit a ticket, and we'll address it promptly.",
  },
  {
    count: 10,
    question: "Do you deal in used copies?",
    answer: "No, we only deal in new copies.",
  },
];

export const ABOUTUS_ARRAY = [
  {
    icon: "/icons/home/Shape4.svg",
    title: "Supply Chain reach",
    description:
      "We collaborate with publishers and distributors of all sizes and are always open to expanding our network by adding new partners.",
  },
  {
    icon: "/icons/home/Shape.svg",
    title: "Practices",
    description:
      "We respect all geographical rights and deal exclusively in legitimate books sourced from trusted and authentic suppliers. We adhere to the highest standards of trade practices.",
  },
  {
    icon: "/icons/home/Shape2.svg",
    title: "Try Us",
    description:
      "Mutual understanding and competitive business practices often work better than legal formalities. Give us a try with a list of books you haven't been able to source from your regular vendors.",
  },
  {
    icon: "/icons/home/Shape3.svg",
    title: "No Meta Data",
    description:
      "We do not provide any metadata service. Our catalog is solely for informational purposes, offering pricing and availability details for books.",
  },
];

export const navigationMenu = [
  {
    label: "Home",
    href: ROUTES?.HOME,
    className: "font-semibold text-dark-gray-1",
  },
  {
    label: "Features",
    href: "/#features",
    className: "hover:bg-transparent hover:underline",
    activeClassName: "bg-custom-gradient bg-clip-text !text-transparent",
  },
  {
    label: "About Us",
    href: "/#about",
    className: "hover:bg-transparent hover:underline",
  },
  {
    label: "Q&A",
    href: "/#question-and-answer",
    className: "hover:bg-transparent hover:underline",
  },
  {
    label: "APIs",
    href: ROUTES.SEARCH_API,
    className: "hover:bg-transparent hover:underline",
  },
  {
    label: "Order",
    href: ROUTES.ORDER,
    className: "hover:bg-transparent hover:underline",
  },
  {
    label: "Contact Us",
    href: ROUTES?.CONTACT_US,
    className: "hover:bg-transparent hover:underline",
  },
];
