import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SearchIcon } from "lucide-react";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";
import { Input } from "../../components/ui/input";
import ROUTES from "../../../src/services/AppConstant";
import { useDispatch, useSelector } from "react-redux";
import { API_CONSTANT } from "../../../src/services/ApiConstant";
import { setBookOrders } from "../../../src/store/orders/reducer";
import { getFromAsync, SESSION_KEY } from "../../../src/lib/utils";
import { postWithOutToken } from "../../../src/services/ApiService";
import { BookOrderComponent } from "../../../src/components/postOrder";

const SerachBook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bookOrders } = useSelector((state) => state);
  const currentUserData = getFromAsync(SESSION_KEY?.USER_DATA);

  const [cartList, setCartList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);
  const [bookSearchData, setBookSearchData] = useState([]);

  useEffect(() => {
    if (!currentUserData?.IsLoggedIn) {
      navigate(ROUTES?.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData]);

  useEffect(() => {
    if (bookOrders && bookOrders?.bookOrders?.length > 0) {
      setCartList(bookOrders?.bookOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookOrders?.bookOrders]);

  const bookSearchApi = (keyword, statsId) => {
    setSearchLoader(true);
    const obj = {
      IPAddress: "",
      SearchText: keyword,
      StatsTermId: statsId,
      AppUserId: currentUserData?.AppUserId,
    };
    postWithOutToken(`${API_CONSTANT?.BOOK_SEARCH}`, obj)
      .then((res) => {
        if (res?.data?.IsError === true) {
          toast.error(res?.data?.ErrorMessage);
        } else {
          setBookSearchData(res?.data?.BookList);
        }

        setSearchLoader(false);
      })
      .catch((err) => {
        toast.error(err?.data?.ErrorMessage || err?.message || String(err));
        setSearchLoader(false);
      });
  };

  const handleAddToCart = (book) => {
    setCartList((prevCartList) => {
      const existingItem = prevCartList.find(
        (item) => item.ISBN10 === book.ISBN10,
      );

      let updatedCartList;
      if (!existingItem) {
        updatedCartList = [...prevCartList, { ...book, quantity: 1 }];
      } else {
        updatedCartList = prevCartList.map((item) =>
          item.ISBN10 === book.ISBN10
            ? { ...item, quantity: item.quantity + 1 }
            : item,
        );
      }

      dispatch(setBookOrders(updatedCartList));
      return updatedCartList;
    });
  };

  const handleQuantityChange = (book, delta) => {
    const updatedCart = cartList.map((item) => {
      if (item.ISBN10 === book.ISBN10) {
        const newQuantity = item.quantity + delta;
        return { ...item, quantity: newQuantity };
      }
      return item;
    });

    const filteredCart = updatedCart.filter((item) => item.quantity > 0);
    setCartList(filteredCart);

    dispatch(setBookOrders(filteredCart));
  };

  const handlePostOrder = () => {
    const orders = cartList?.filter((item) => item.quantity > 0);
    dispatch(setBookOrders(orders));

    if (!currentUserData?.IsLoggedIn) {
      navigate(ROUTES?.LOGIN);
    } else {
      navigate(ROUTES?.CHECK_OUT);
    }
  };

  return (
    <div>
      <div className="mb-[30px] h-full w-full bg-home-bg pb-[50px] pt-20">
        <div className="m-auto max-w-7xl px-[15px]">
          <div className="flex justify-center">
            <div className="w-full md:max-w-[668px]">
              <div
                className={`${bookSearchData?.length === 0 ? "h-[226px]" : "h-full"} py-5`}
              >
                <div className="relative flex h-[50px] items-center justify-center rounded-[6px] bg-white shadow-Searchbar md:h-[63px]">
                  <SearchIcon className="mx-2 size-7 text-gray-500" />
                  <Input
                    autoFocus={true}
                    value={searchText}
                    placeholder="Business"
                    className="border-none bg-transparent pl-2 pr-16 shadow-none focus:outline-none focus-visible:ring-0"
                    onChange={(e) => {
                      if (e?.target?.value === "") {
                        setBookSearchData([]);
                      }
                      setSearchText(e?.target?.value);
                    }}
                    onKeyDownCapture={(e) =>
                      e.key === "Enter" && bookSearchApi(searchText, "")
                    }
                  />
                  <span
                    className="mx-2 cursor-pointer pr-1 text-base text-blue-400"
                    onClick={() => bookSearchApi(searchText, "")}
                  >
                    Search
                  </span>
                </div>

                {searchLoader ? (
                  <Spinner spinnerClass="top-20" />
                ) : (
                  searchText &&
                  bookSearchData?.length !== 0 && (
                    <div className="pt-5 text-left">
                      {bookSearchData && bookSearchData.length > 0 && (
                        <BookOrderComponent
                          cart={cartList}
                          bookList={bookSearchData}
                          handleAddToCart={handleAddToCart}
                          handlePostOrder={handlePostOrder}
                          handleQuantityChange={handleQuantityChange}
                        />
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SerachBook;
